<script lang="ts" setup>

import {mdiCheckDecagramOutline} from "@mdi/js";
import {computed} from "vue";

import type {Schemas} from "~/api-types/storeApiTypes";
import {usePrice} from "#imports";

const {getFormattedPrice} = usePrice();

const props = withDefaults(
    defineProps<{
      product: Schemas["Product"];
      circleTextClass?: string,
      background?: string,
      progressBackground?: string,
      compact?: boolean
    }>(),
    {
      circleTextClass: 'text-gray950',
      background: 'gray50',
      progressBackground: 'gray200',
      compact: false,
    },
);

const currentFunding = computed(() => {
  return getFormattedPrice(props.product?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.currentFundingTotal ?? 0.0)
})

const fundingLimit = computed(() => {
  return props.product?.stock * Number(props.product?.calculatedPrice?.totalPrice)
})

const fundingPercent = computed(() => {
  const current = props.product?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.currentFundingTotal ?? 0.0
  const limit = props.product?.stock * Number(props.product?.calculatedPrice?.totalPrice)

  return Math.min(Math.max((current / limit) * 100, 0), 100)
})

const isInFuture = computed(() => {
  const fundingStartDate = props.product?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.extensions?.fundingInformationExtension?.fundingStartDate
  if (!fundingStartDate) return false

  const now = (new Date()).getTime()
  const startDate = (new Date(fundingStartDate)).getTime()
  return (startDate - now) > 0;
})

</script>

<template>
  <template
      v-if="fundingLimit !== 0 && !isInFuture && !product.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.isCompleted">
    <v-card :color="background" border="0" class="mt-4" rounded="lg">
      <v-card-text :class="{'pa-2': compact}">
        <div class="mb-2">
          <span class="font-weight-bold">{{ currentFunding }}</span> von {{ getFormattedPrice(fundingLimit) }}
        </div>

        <v-row align="center">
          <v-col>
            <v-progress-linear
                :bg-color="progressBackground"
                :model-value="fundingPercent"
                :rounded-bar="true"
                bg-opacity="1"
                color="blue500"
                height="8"
                rounded="lg"
            />
          </v-col>

          <v-col class="text-center" cols="auto">
            {{ fundingPercent.toFixed(2) }} %
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>

  <ProductCardCountDown
      v-else-if="isInFuture"
      :circle-text-class="circleTextClass"
      :start-date="product.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.extensions?.fundingInformationExtension?.fundingStartDate"
      style="height: 9.75rem;"
  />

  <v-row
      v-else-if="product.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct?.isCompleted"
      align="center"
      class="mt-4"
      justify="space-between"
      style="height: 5.75rem"
  >
    <v-col cols="auto">
      <span class="font-weight-bold">{{ currentFunding }}</span>
    </v-col>

    <v-col cols="auto">
        <span class="text-green600 font-weight-bold d-flex align-center">
          <v-icon :icon="mdiCheckDecagramOutline" class="me-1"/>
          Erfolgreich finanziert
        </span>
    </v-col>
  </v-row>
</template>
